<template>
  <div class="headbar" :style="{backgroundColor: bgColor}">
    <div class="wrap">
      <div class="left" @click="back">
        <img src="@/assets/img/base/back2.png" class="back-icon" />
        <div>{{$t('返回')}}</div>
      </div>
      <div class="title">{{ title }}</div>
      <div class="right">
        <slot name="right">
          <div v-if="rightText" @click="rightClick">{{ rightText }}</div>
        </slot>
      </div>
    </div>
  </div>

  <div class="placeholder"></div>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: "#080535"
    },
    rightText: {
      type: String,
      default: null
    }
  },
  components: {},
  setup() {
    return {}
  },
  methods: {
    back() {
      this.$router.back()
    },
    rightClick() {
      this.$emit('rightClick')
    }
  }
}
</script>

<style scoped>
  .headbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 10px;
    height: 50px;
    display: flex;
    align-items: center;
    z-index: 9;
  }

  .placeholder {
    height: 50px;
  }

  .wrap {
    width: 100%;
    position: relative;
  }

  .left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #e56efa;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #e56efa;
  }

  .back-icon {
    margin-right: 6px;
    width: 19px;
    height: 19px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #e56efa;
    text-align: center;
  }
</style>